import { ArcButton, ArcIcon } from '@articulate/design-system'
import { VFC } from 'react'

import ModalContainer, { Props as ModalProps } from '../ModalContainer/ModalContainer'
import { SubmitState } from '../ReportForm/ReportForm'
import styles from './MessageModal.module.scss'

export type ModalContent = {
  title: string
  message: JSX.Element | string
}

type Props = ModalProps & {
  modalContent?: ModalContent
  submitState?: SubmitState
  issueType?: string
}

const MessageModal: VFC<Props> = ({ modalContent, open, setOpen, submitState, issueType }: Props)=> {
  const thankYouMessage = `Thank you, your ${issueType} has been submitted.`

  return submitState !== SubmitState.complete
    ? <ModalContainer open={open} setOpen={setOpen}>
      <div className={styles.container}>
        <div className={styles.contentContainer}>
          <div className={`arc-font-subheading-lg ${styles.title}`}>{modalContent?.title}</div>
          <div className={styles.message}>{modalContent?.message}</div>
        </div>
      </div>
    </ModalContainer>
    : <ModalContainer open={open} setOpen={setOpen}>
      <div className={styles.successContainer}>
        <ArcButton
          className={styles.xIcon}
          icon="fa-regular fa-xmark"
          layout="icon"
          shape="square"
          size="md"
          variant="tertiary"
          onClick={() => setOpen(false)}
          aria-label="Cancel"
        />
        <ArcIcon
          className={styles.checkmarkIcon}
          icon="fa-regular fa-circle-check"
          size="3rem"
          aria-label="Success checkmark"
        />
        <div className={styles.successMessage}>Success</div>
        <div className={styles.thankYouMessage}>{thankYouMessage}</div>
      </div>
    </ModalContainer>
}

export default MessageModal
