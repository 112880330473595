import getConfig from 'next/config'

import styles from './Footer.module.scss'

const { publicRuntimeConfig } = getConfig()

const year = new Date().getFullYear()
const wwwUri = publicRuntimeConfig.WWW_URI

const Footer = (): React.ReactElement => {
  return (
    <div className={`arc-font-body-sm ${styles.footer}`}>
      <span>&copy; {year} Articulate Global, LLC</span>
      <div><a href={`${wwwUri}/360-terms-of-service`}>Terms</a></div>
      <div><a href={`${wwwUri}/trust/privacy`}>Privacy</a></div>
    </div>
  )
}

export default Footer
