import { ArcButton, ArcTextInput, ArcTextInputType } from '@articulate/design-system'
import { InputCoreOnChangeDetail, InputCoreOnChangeEvent, InputCoreOnChangeHandler } from '@articulate/design-system/dist/components/_Internal/Input'
import React, { ComponentProps, useEffect, useState } from 'react'
import { FieldError, FieldValues, RegisterOptions, useForm } from 'react-hook-form'

import { upload } from '../../lib/fileUpload'
import { createIssueFromFormData, postIssue } from '../../lib/services/issue'
import { ApplicationType } from '../../model/Application'
import { Product } from '../../model/Product'
import { IssueType } from '../../model/Requests'
import ButtonGroup from '../ButtonGroup/ButtonGroup'
import FileGroup from '../FileGroup/FileGroup'
import MessageModal, { ModalContent } from '../MessageModal/MessageModal'
import styles from './ReportForm.module.scss'

type Props = {
  product: Product
}

type IssueState = {
  type: IssueType
  label: string
  buttonText?: string
  descriptionPlaceholderText: string
}

const inputStates: IssueState[] = [
  {
    type: IssueType.feature,
    label: 'Share feedback',
    descriptionPlaceholderText: `Anything else you'd like us to know?`
  },
  {
    type: IssueType.issue,
    label: 'Report a problem',
    buttonText: 'Report Issue',
    descriptionPlaceholderText: `Anything else you'd like us to know?`
  }
]

export enum SubmitState { pending, complete, working }
// eslint-disable-next-line @typescript-eslint/ban-types
const buttonTextRecords: Record<SubmitState, Function> = {
  [SubmitState.pending]: (buttonText: string) => buttonText,
  [SubmitState.complete]: () => 'Thanks!',
  [SubmitState.working]: () => 'Working...'
}

const ReportForm = ({ product }: Props): React.ReactElement => {
  // UI state
  const [availableInputStates]                = useState(inputStates)
  const [activeIssueType, setActiveIssueType] = useState(availableInputStates[0])
  const [resultModalOpen, setResultModalOpen] = useState(false)
  const [modalContent, setModalContent]       = useState<ModalContent>()
  const [submitState, setSubmitState]         = useState(SubmitState.pending)
  const [application, setApplication]         = useState<string | undefined>(undefined)
  const [version, setVersion]                 = useState<string | undefined>(undefined)

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)
    const application = searchParams.get('application') ?? undefined
    const version = searchParams.get('version') ?? undefined

    if (application === ApplicationType.rise || application === ApplicationType.storyline) {
      setApplication(application)
    }

    if (version) {
      setVersion(version)
    }
  }, [])

  // Form state
  const [currentFiles, setCurrentFiles] = useState<File[]>([])
  const { register, handleSubmit, reset, formState: { errors } } = useForm()

  const submit = async(data: FieldValues) => {
    const issue = createIssueFromFormData(data, product, activeIssueType.type, currentFiles.map(file => file.name), application, version)

    try {
      const response = await postIssue(issue)
      const uploadUrls = response.uploadUrls ?? []
      const failedFiles: string[] = []

      if (issue.type === IssueType.issue && uploadUrls.length > 0 && currentFiles) {
        for (const uploadUrl of uploadUrls) {
          const file = currentFiles.find(file => file.name === uploadUrl.fileName)

          if (file) {
            setModalContent({ message: `uploading ${uploadUrl.fileName}...`, title: 'Working' })
            await upload(uploadUrl.uploadUrl, file)
              .then(res => res.status)
              .catch(() => {
                failedFiles.push(file.name)
              })
          }
        }
      }

      const message = issue.type === IssueType.issue
        ? `Issue "${response.issueId}" has been created` + ((failedFiles.length > 0)
          ? ` but the following files failed to upload: ${failedFiles.join(', ')}.`
          : '.')
        : 'Your feedback has been submitted.'

      setModalContent({ title: 'We hear you!', message })
      setSubmitState(SubmitState.pending)
    } catch (error: unknown) {
      setModalContent({ title: 'Oops', message: (error as Error).message })
    }

    setSubmitState(SubmitState.complete)
    reset()
  }

  useEffect(() => {
    modalContent && setResultModalOpen(true)
  },
  [modalContent])

  useEffect(() => {
    if (!resultModalOpen) {
      setModalContent(undefined)
      setSubmitState(SubmitState.pending)
    }
  },
  [resultModalOpen])

  const onActiveIssueTypeChangeRequested = (issueType: string) => {
    const requestedState = inputStates.find(state => state.label === issueType)
    setActiveIssueType(requestedState || availableInputStates[0])
  }

  const selectedFilesChanged = (files: File[]) => {
    setCurrentFiles(files)
  }

  const getMessageForEmailError = (error: FieldError): string => {
    const emailErrorMessages: { [key: string]: string } = {
      required: 'Please add your email',
      pattern: 'Please enter a valid email'
    }

    return emailErrorMessages[error?.type] || emailErrorMessages.pattern
  }

  const registerWrapper = (
    name: string,
    validation: RegisterOptions,
    inputProps: Omit<ComponentProps<'input'>, 'type' | 'onChange'> & { onChange?: InputCoreOnChangeHandler, type?: ArcTextInputType }
  ) => {
    const reg = register(name, validation)

    return {
      ...reg,
      inputProps: {
        ...reg,
        ...inputProps,
        id: name,
        onChange: (_detail: InputCoreOnChangeDetail, event: InputCoreOnChangeEvent) => reg.onChange(event)
      }
    }
  }

  return (
    <>
      <div className={styles.section}>
        <ButtonGroup elements={availableInputStates.map(issueState => issueState.label)}
          activeElement={activeIssueType.label}
          onElementChangeRequested={onActiveIssueTypeChangeRequested} />
      </div>

      <form onSubmit={handleSubmit(submit)} noValidate>
        <div className={styles.reportForm}>
          <div className={styles.section}>
            <div className={styles.inputFloatLabel}>
              <div
                className={`arc-font-body-xsm validationMessage ${errors.userName ? '' : 'hidden'}`}
              >
              Please add your name
              </div>
              <ArcTextInput
                {
                  ...registerWrapper('userName',
                    {
                      required: true,
                      minLength: 2
                    },
                    {
                      autoComplete: 'email',
                      className: 'default'
                    })
                }
                layout="fill"
                headerLabel="Your name"
              />
            </div>
            <div className={styles.inputFloatLabel}>
              <div className={`arc-font-body-xsm validationMessage ${errors.email ? '' : 'hidden'}`}>
                {getMessageForEmailError(errors.email)}
              </div>
              <ArcTextInput
                {
                  ...registerWrapper('email',
                    {
                      required: true,
                      pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    },
                    {
                      type: 'email',
                      autoComplete: 'email',
                      className: 'default'
                    })
                }
                layout="fill"
                headerLabel="Your email"
              />
            </div>
            <div className={styles.inputFloatLabel}>
              <div className={`arc-font-body-xsm validationMessage ${errors.issueTitle ? '' : 'hidden'}`}>
              Please add a summary
              </div>
              <ArcTextInput
                {
                  ...registerWrapper('issueTitle',
                    {
                      required: true,
                      minLength: 2
                    },
                    {
                      autoComplete: 'off',
                      className: 'default'
                    })
                }
                layout="fill"
                headerLabel="Summary of your feedback"
              />
            </div>
          </div>

          <div className={styles.section}>
            <label htmlFor="issueDescription" className={styles.textAreaLabel}><span className="arc-font-subheading-sm">{activeIssueType.descriptionPlaceholderText}</span></label>
            <textarea {...register('issueDescription', { required: true, minLength: 2 })} id="issueDescription" className="default" rows={8}/>
            <div className={`arc-font-body-xsm validationMessage ${errors.issueDescription ? '' : 'hidden'}`}>
              {`Anything else you'd like us to know?`}
            </div>
          </div>

          { activeIssueType.type === IssueType.issue
            ? <>
              <div className={styles.section}>
                <div className={styles.filePickerContainer}>
                  <FileGroup onSelectedFilesChanged={selectedFilesChanged} />
                </div>
              </div>
            </>
            : <></>
          }
        </div>

        <div className={styles.section}>
          <ArcButton
            className={styles.submitButton}
            disabled={submitState !== SubmitState.pending}
            text={buttonTextRecords[submitState](activeIssueType.buttonText ?? product.feedbackSubmitText)}
            type="submit"
          />
        </div>
      </form>

      <MessageModal
        modalContent={modalContent}
        open={resultModalOpen}
        setOpen={setResultModalOpen}
        submitState={submitState}
        issueType={activeIssueType.type === IssueType.issue ? 'issue' : 'feedback' } />
    </>
  )
}

export default ReportForm
