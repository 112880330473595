/* eslint-disable @next/next/no-img-element */
import { ArcButton } from '@articulate/design-system'
import { MouseEvent } from 'react'

import styles from './FileView.module.scss'

type Props = {
  onRemoved: (file?: File)=> void
  file?: File
}

const FileView = ({ onRemoved, file }: Props): JSX.Element => {
  const clearCurrentFile = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    onRemoved(file)
  }

  return (
    <div className={styles.container}>
      <div className={styles.containerContent}>
        {file?.name}
      </div>

      <ArcButton
        className="iconButton"
        icon="fa-regular fa-xmark"
        text="Remove"
        variant="tertiary"
        size="md"
        onClick={clearCurrentFile}
        aria-label="Remove file"
      />
    </div>
  )
}

export default FileView
