import React from 'react'

import styles from './ButtonGroup.module.scss'

type Props = {
  elements: string[]
  activeElement: string
  onElementChangeRequested: (elementName: string)=> void
}

const ButtonGroup = ({ elements, activeElement, onElementChangeRequested }: Props): React.ReactElement => {
  const buttonClick = async(event: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
    event.preventDefault()
    const elementName = event.currentTarget.name
    onElementChangeRequested(elementName)
  }

  const isActive = (element: string) => activeElement === element

  return (
    <div className={styles.buttonContainer} >
      {elements.map(element => (
        <button key={element}
          type="button"
          name={element}
          className={`arc-font-subheading-md ${styles.button} ${isActive(element) ? styles.buttonActive : ''}`}
          onClick={buttonClick}
        >
          <span className={`${isActive(element) ? styles.spanActive : ''}`}>{element}</span>
        </button>
      ))}
    </div>
  )
}

export default ButtonGroup
