/* eslint-disable @next/next/no-img-element */
import { ArcButton } from '@articulate/design-system'
import { ChangeEvent, useRef, useState } from 'react'

import styles from './FilePicker.module.scss'

type Props = {
  onFileSelected: (files: File[])=> void
}

const FilePicker = ({ onFileSelected }: Props): JSX.Element => {
  const [files, setFiles] = useState([])
  const inputFileRef = useRef<HTMLInputElement>(null)

  const onFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target

    const filesOrEmpty = files?.length
      ? Array.from(files)
      : []

    setFiles([]) // Reset the <input> internal state to avoid 'cached' files.
    onFileSelected(filesOrEmpty)
  }

  return (
    <>
      <label
        htmlFor="fileSelector"
        className={styles.filePickerLabel}
      >
        <div className={`arc-font-body-md ${styles.selectFilePickerLabelText}`}>
          Select File(s)
        </div>

        <ArcButton
          className="iconButton"
          aria-label="Attach file"
          icon="fa-regular fa-arrow-up-from-bracket"
          text="Browse"
          variant="tertiary"
          size="md"
          onClick={() => inputFileRef?.current?.click()}
        />
      </label>

      <input
        ref={inputFileRef}
        className={styles.filePickerInput}
        id="fileSelector"
        type="file"
        onChange={onFileChange}
        value={files}
        multiple
        hidden
      />
    </>
  )
}

export default FilePicker
